.jackpots {
  background: linear-gradient(180deg, #15171E 0%, #310036 100%);
  padding: 1em 1.5em 1.5em 1.5em;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;

  &__logo {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
    width: 100%;
    &-img {
      width: 11.5em;
      z-index: 2;
    }
  }
  &__glow {
    position: absolute;
    top: -2em;
    z-index: 1;
    @media(min-width: 834px) {
      width: 100%;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    z-index: 2;
    margin-top: 1em;
    justify-content: center;
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 8.25em;
    margin-top: 0.8em;
    padding: 0.5em;
    background: rgba(0, 0, 0, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    &:first-of-type{
      margin-top: 0;
    }
    @media(min-width: 668px) {
      width: calc(50% - 1em);
      &:nth-of-type(odd) {
        margin-right: 1em;
      }
      &:first-of-type {
        margin-top: 0.8em;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
    @media(min-width: 834px) {
      height: 10em;
    }
    & > img {
      width: 6.75em;
      height: 3em;
    }
    & > p {
      font-size: inherit;
      color: #fff;
      font-weight: 600;
      @media(min-width: 834px) {
        font-size: 0.8em;
      }
    }
  }
  &__play {
    background: linear-gradient(272.29deg, #720A7B 0%, #A120A9 50.52%, #720A7B 100%);
    border-radius: 8px;
    width: 100%;
    padding: 1em 0 ;
    margin-top: 2em;
    font-size: 1em;
    @media(min-width: 376px) {
      width: 320px;
    }
    @media(min-width: 1024px) {
      width: 20em;
    }
    & > p {
      font-size: 1.25em;
      color: #fff;
      font-weight: 800;
    }
  }
}
