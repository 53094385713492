.features {
  &__main {
    background: linear-gradient(180deg, #15171E 0%, #100F3E 100%);
    box-sizing: border-box;
    padding: 0.5em 1em 1.5em 1em;
    overflow: auto;
    height: 100vh;
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5em;
  }

  &__content {
    display: flex;
    flex-direction: column;
    @media(min-width: 835px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 10em;
    margin-bottom: 0.75em;
    @media(min-width: 835px) {
      width: calc(50% - 0.75em);
      margin-right: 0.75em;
      height: 9em;
      &:nth-of-type(even) {
        margin-right: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }

    & img {
      align-content: center;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
