body {
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
  * {
    box-sizing: border-box;
  }
}

.ehub {
  @media(min-width: 376px) {
    font-size: 4.8vw;
  }
  @media(min-width: 558px) {
    font-size: 3vw;
  }
  @media(min-width: 1200px) {
    font-size: 36px;
  }
}
